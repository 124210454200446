import { useContext } from 'react'

// styles
import './styles.css'

// components
import ContactForm from '../../components/ContactForm'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function Contact() {
    const { translation } = useContext(LanguageContext)

    return (
        <main id='Contact-main-body'>
            <section id='Contact-section'>
                <h2 id='Contact-h2-title'>{translation.contact[1]}</h2>

                <span id='Contact-span-number'>
                    {translation.contact[2]} <a id='Contact-span-a' href="tel:(351)939789158">939789158</a>
                </span>

                <span id='Contact-span-email'>
                    {translation.contact[3]} <a id='Contact-span-a' href='mailto:carmencruz.art@gmail.com'>carmencruz.art@gmail.com</a>
                </span>
            </section>

            <ContactForm />
        </main>
    )
}