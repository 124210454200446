// Supabase
import { supabase } from '../supabase'

export default async function getImagesText() {
    const { data, error } = await supabase
    .storage
    .from('arts')
    .list('home')

    if (error) return console.log(error)

    data.forEach((image, index) => {
        const link = `https://dsemcgdgrvoshhzsvqao.supabase.co/storage/v1/object/public/arts/home/${image.name}`.replace(/ /g, "%20")
        const name = image.name.split('.')[0]
       
        data[index] = (
            <div className='Home-div-artContainer' key={image.id}>
                <img className='Home-img-art' src={link} alt={`${name} de Carmen Cruz`} />

                <h3 className='Home-h3-artName'>{name}</h3>
            </div>
        )
    })
    
    return data
}