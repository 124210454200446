import { createContext, useState, useEffect } from 'react'

// translations
import pt from '../translations/pt.json'
import en from '../translations/en.json'

export const LanguageContext = createContext()

export default function LanguageProvider({ children }) {
    const language = navigator.language.split('-')[0]
    
    const [translation, setTranslation] = useState(pt)
    
    const [preferredLanguage, setPreferredLanguage] = useState('pt')

    function changeLanguage() {
        if (preferredLanguage == 'pt') {
            setTranslation(en)
            setPreferredLanguage('en')
        } else {
            setPreferredLanguage('pt')
            setTranslation(pt)
        }
    }

    useEffect(() => {
        if (language == 'pt') {

        } else {
            setTranslation(en)
            setPreferredLanguage('en')
        }
    }, [])



    return (
        <LanguageContext.Provider value={{ translation, preferredLanguage, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}