// Email JS
import emailjs from '@emailjs/browser'

export default function sendEmail(name, surname, email, message, setName, setSurname, setEmail, setMessage, setLoading) {

    if (!name || !surname || !email || !message) {
        return alert('Preencha todos os campos!')
    }

    setLoading(true)

    const templateParms = {
        from_name: name,
        surname: surname,
        email: email,
        message: message
    }

    emailjs.send('service_2ipdjal', 'template_9qzdibb', templateParms, 'eHq3PWWIhB30BSJ9T')
    .then((response) => {
        console.log('Email enviado!', response.status, response.text)
        alert('Mensagem enviada com sucesso!')

        setName('')
        setSurname('')
        setEmail('')
        setMessage('')
    })
    .catch((error) => {
        console.log('Email não enviado!', error.status, error.text)
        alert('Tivemos um problema para enviar sua mensagem. Envie diretamente um e-mail, para: carmencruz.art@gmail.com')
    })
    .finally(() => setLoading(false))
}