import { useContext } from 'react'

// styles
import './styles.css'

// images
import IconInstagram from '../../assets/images/icons/Instagram.png'
import IconFacebook from '../../assets/images/icons/Facebook.png'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function Footer() {
    const { translation } = useContext(LanguageContext)

    return (
        <footer id='Footer-footer-body'>
            <div id='Footer-div-containerLocation'>
                <span className='Footer-span-title'>{translation.footer[1]}</span>

                <span className='Footer-span-genericText'>Portugal</span>
            </div>

            <div id='Footer-div-containerSocialMedia'>
                <span className='Footer-span-title'>{translation.footer[2]}</span>

                <div id='Footer-div-containerButtonsSocialMedia'>
                    <a href='https://www.instagram.com/carmencruzart/'>
                        <img className='Footer-img-iconSocialMedia' src={IconInstagram} alt='Icone do Instagram' />
                    </a>

                    <a href='https://www.facebook.com/profile.php?id=100093800742978'>
                        <img className='Footer-img-iconSocialMedia' src={IconFacebook} alt='Icone do Facebook' />
                    </a>
                </div>
            </div>

            <div id='Footer-div-containerContact'>
                <span className='Footer-span-title'>{translation.footer[3]}</span>

                <a href="tel:(351)939789158">
                    <span className='Footer-span-genericText'>939789158</span>
                </a>

                <a href='mailto:carmencruz.art@gmail.com'>
                    <span className='Footer-span-genericText'>carmencruz.art@gmail.com</span>
                </a>
            </div>
        </footer>
    )
}