import { useState, useContext } from 'react'

// React Router Dom
import { NavLink } from 'react-router-dom'

// styles
import './styles.css'

// contexts
import { LanguageContext } from '../../contexts/language'

// images
import IconInstagram from '../../assets/images/icons/Instagram.png'
import IconFacebook from '../../assets/images/icons/Facebook.png'
import IconClose from '../../assets/images/icons/close.png'
import IconMenu from '../../assets/images/icons/menu.png'

export default function Header() {
    const { translation, preferredLanguage, changeLanguage } = useContext(LanguageContext)

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <header className={menuOpen ? 'header_open' : ''}>
            <div className='container_superior'>
                <h1 id='Header-header-div-h1'>Carmen Cruz</h1>

                <div id='Header-div-containerButtonLanguageAndMenu'>
                    <button id='Header-button-changeLanguageOne' onClick={changeLanguage}>
                        {preferredLanguage}
                    </button>

                    <button className='button_menu' onClick={() => setMenuOpen(!menuOpen)}>
                        <img id='Header-img-iconCloseAndMenu' src={menuOpen ? IconClose : IconMenu} alt={menuOpen ? 'Icone de fechar cabecalho' : 'Icone de abrir cabecalho'} />
                    </button>
                </div>

            </div>

            <nav className={menuOpen ? 'nav_open' : ''}>
                <NavLink to='/' onClick={() => setMenuOpen(false)}>{translation.header[1]}</NavLink>

                <NavLink to='/obras' onClick={() => setMenuOpen(false)}>{translation.header[2]}</NavLink>

                <NavLink to='/artista' onClick={() => setMenuOpen(false)}>{translation.header[3]}</NavLink>

                <NavLink to='/contato' onClick={() => setMenuOpen(false)}>{translation.header[4]}</NavLink>
            </nav>

            <div className={menuOpen ? 'container_social_media_open' : 'container_social_media'}>
                <button id='Header-button-changeLanguageTwo' onClick={changeLanguage}>
                    {preferredLanguage}
                </button>

                <a id='header-div-a-img-icon' href='https://www.instagram.com/carmencruzart/'>
                    <img id='header-div-a-img-icon' src={IconInstagram} alt='Icone do Instagram' />
                </a>

                <a id='header-div-a-img-icon' href='https://www.facebook.com/profile.php?id=100093800742978'>
                    <img id='header-div-a-img-icon' src={IconFacebook} alt='Icone do Facebook' />
                </a>
            </div>
        </header>      
    )
}