import { useState, useContext } from 'react'

// styles
import './styles.css'

// services
import sendEmailServices from '../../services/sendEmail'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function ContactForm() {
    const { translation } = useContext(LanguageContext)

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    async function sendEmail(event) {
        event.preventDefault()

        sendEmailServices(name, surname, email, message, setName, setSurname, setEmail, setMessage, setLoading)
    }

    if (loading) {return }

    return (
        <form id='ContactForm-form-body' onSubmit={sendEmail}>
            <h2 id='ContactForm-h2-title'>{translation.contactForm[1]}</h2>

            <div id='ContactForm-div-containerNameAndSurname'>
                <div className='ContactForm-div-containerLabelAndInput'>
                    <label className='ContactForm-label' htmlFor='name'>{translation.contactForm[2]}</label>

                    <input 
                        id='name'
                        type='text'
                        placeholder=''
                        autoComplete='given-name'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </div>

                <div className='ContactForm-div-containerLabelAndInput'>
                    <label className='ContactForm-label' htmlFor='surname'>{translation.contactForm[3]}</label>

                    <input 
                        id='surname'
                        type='text'
                        placeholder=''
                        autoComplete='family-name'
                        onChange={(e) => setSurname(e.target.value)}
                        value={surname}
                    />
                </div>
            </div>

            <div className='ContactForm-div-containerLabelAndInput'>
                <label className='ContactForm-label' htmlFor='email'>{translation.contactForm[4]}</label>

                <input 
                    id='email'
                    type='text'
                    placeholder=''
                    autoComplete='email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            </div>

            <div className='ContactForm-div-containerLabelAndInput'>
                <label className='ContactForm-label' htmlFor='message'>{translation.contactForm[5]}</label>   

                <textarea 
                    id='message'
                    placeholder=''
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />
            </div>

            <input id='enviar' type='submit' value={loading ? translation.contactForm[7] : translation.contactForm[6]} disabled={loading} />
        </form>
    )
}