import { useState, useEffect, useContext } from 'react'

// styles
import './styles.css'

// React Router Dom
import { NavLink } from 'react-router-dom'

// components
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'

// services
import getImagesHomeServices from '../../services/getImagesHome'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function Home() {
    const { translation } = useContext(LanguageContext)

    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)

    async function getImages() {
        const data = await getImagesHomeServices()
        setImages(data)
    }

    useEffect(() => {
        getImages()
        setLoading(false)
    }, [])

    if (loading) return;

    return (
        <main id='Home-main-body'>
            <h2 id='Home-h2-subtitle'>{translation.home[1]}</h2>

            <section className="Home-section-dubleArtContainer">
                {images}
            </section>

            <NavLink to='/obras' id='Home-a-buttonSeeAllArts'>{translation.home[2]}</NavLink>

            <section className='Home-section-contactForm'>
                <ContactForm />
            </section>

            <Footer />
        </main>
    )
}