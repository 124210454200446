import { useState, useEffect, useContext } from 'react'

// styles
import './styles.css'

// components
import Footer from '../../components/Footer'

// services
import getImagesServices from '../../services/getImages'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function Arts() {
	const { translation } = useContext(LanguageContext)

	const [images, setImages] = useState([])
	const [loading, setLoading] = useState(true)

	function DivsSection({ divs }) {
		function criarParesDeSections() {
			const elementosHTML = [];

			for (let i = 0; i < divs.length; i += 2) {
				const parDivs = divs.slice(i, i + 2);
				const elementosDivs = parDivs.map(function (div, index) {
					return <div key={index} className="sua-classe-de-div">{div}</div>;
				});

				elementosHTML.push(
					<section key={i} className="Arts-div-dubleArtContainer">
						{elementosDivs}
					</section>
				);
			}

			return elementosHTML;
		}

		return (
			<div>
				{criarParesDeSections()}
			</div>
		);
	}

	async function getImages() {
		const data = await getImagesServices()
		setImages(data)
	}

	useEffect(() => {
		getImages()
		setLoading(false)
	}, [])

	if (loading) return

	return (
		<main id='Arts-main-body'>
			<h2 id='Arts-h2-mainTitle'>{translation.arts[1]}</h2>

			<section id='Arts-section-arts'>
				<DivsSection divs={images} />
			</section>

			<Footer />
		</main>
	)
}