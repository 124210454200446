import { useContext } from 'react'

// styles
import './styles.css'

// components
import Footer from '../../components/Footer'

// contexts
import { LanguageContext } from '../../contexts/language'

export default function Artist() {
    const { translation } = useContext(LanguageContext)

    return (
        <main id='Artist-main-body'>
            <h2 id='Artist-h2-mainTitle'>{translation.artist[1]}</h2>

            <img 
                id='Artist-img-photoCarmen'
                src='https://dsemcgdgrvoshhzsvqao.supabase.co/storage/v1/object/public/arts/artist/Carmen%20Cruz.jpg' 
                alt='Foto da artista Carmen Cruz' 
            />

            <section id='Artist-section-textCarmen'>
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[1]}
                </p>

                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[2]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[3]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[4]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[5]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[6]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[7]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[8]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[9]}
                </p>
                
                <p className='Artist-p-textCarmen'>
                    {translation.textArtist[10]}
                </p>
            </section>

            <Footer />
        </main>
    )
}