// React Router Dom
import { Outlet } from 'react-router-dom'

// components
import Header from './components/Header'

// styles
import './App.css'

export default function App() {
	
	return (
		<div id='App-div-body'>
			<Header />
			<Outlet />
		</div>
	)
}