import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

// styles
import './index.css'

// pages
import Home from './pages/Home'
import Contact from './pages/Contact'
import Artist from './pages/Artist'
import Arts from './pages/Arts'
import Error	 from './pages/Error'

// contexts
import LanguageProvider from './contexts/language'

// React Router Dom
import { createHashRouter, RouterProvider } from 'react-router-dom'
const router = createHashRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <Error />,
		children: [
			{
				path: '/',
				element: <Home />
			},
			{
				path: '/contato',
				element: <Contact />	
			},
			{
				path: '/artista',
				element: <Artist />	
			},
			{
				path: '/obras',
				element: <Arts />	
			}
		]
	}
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<LanguageProvider>
			<RouterProvider router={router} />
		</LanguageProvider>
	</React.StrictMode>
)

